<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Alert :message="requestError" type="alert-danger" v-if="requestError" />
    <div class="row">
      <div class="col-md-12 content_margin_bottom">
        <div class="row">
          <div class="col-md-12 mb-4">
            <h4 class="card-title my-3 float-right">
              <router-link
                :to="`/user/${userId}/enroll_courses`"
                class="btn btn-success btn-sm mr-3"
                >My Courses</router-link
              >
            </h4>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div>
              <b-input-group key="" size="md" class="mb-3">
                <b-form-input
                  placeholder="Enter Certified User"
                  v-model="cert"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    size="sm"
                    text="Button"
                    @click="eSearch"
                    variant="success"
                    >Search</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </div>
            <div v-if="certificates.length <= 0">
              <h2>{{ userTitle }}</h2>
            </div>
            <div class="row mt-4" v-if="certificates.length > 0">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0" v-if="certificates.length > 0">
              <b-table
                :items="certificates"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sorrt-desc.sync="sortDesc"
                :fit-by.sync="sortBy"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                hover
              >
                <!-- <template v-slot:cell(action)="row">
                  <b-button @click="toCert(row.item.e_cert, row.item.id)">
                    Detials
                  </b-button>
                </template> -->
              </b-table>
            </div>
            <div class="row" v-if="certificates.length > 0">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import Alert from "@/components/Alert.vue";
import appConfig from "@/app.config";
import store from "@/state/store";

export default {
  page: {
    title: "Certificated Users",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Alert,
    PageHeader,
    Layout,
  },
  data() {
    return {
      // data table meta
      userId: this.$route.params.id,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "course",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "full_name", sortable: true, label: "Full Name" },
        { key: "username", sortable: true, label: "Email" },
        { key: "phone", sortable: true, label: "Contact" },
        // { key: "Action" },
      ],
      //end of table meta
      loading: false,
      id: null,
      requestError: null,
      success: null,
      error: null,
      key: null,
      title: "Certified Users",
      items: [
        {
          text: "User",
        },
        {
          text: "Certified Users",
          active: true,
        },
      ],
      certificates: {},
      cert: "",
      eSearches: [],
      userTitle: "",
    };
  },
  methods: {
    eSearch() {
      store
        .dispatch("enroll/eCertSearch", this.cert)
        .then((response) => {
          console.log(response.data.result);
          let certs = response.data.result;
          const users = certs.map((user) => {
            return {
              full_name: `${user.first_name} ${user.other_names} ${user.last_name}`,
              username: user.email,
              phone: user.phone,
            };
          });
          this.certificates = users;
          if (this.certificates.length == 0) {
            this.userTitle = "User Not Found!";
          }
        })
        .catch(() => {
          this.loading = false;
          this.success = false;
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  computed: {
    rows() {
      return this.certificates && this.certificates.length;
    },
  },
  created() {},
};
</script>
<style scoped>
th {
  text-transform: uppercase;
}
</style>
